import React, { Fragment, memo, useState } from "react";

import _ from "lodash";
import CopyToClipboard from "react-copy-to-clipboard";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getLocaleKey, translate } from "../../../languages";
import { Button, CreateAlert, Icon, ObjectUtils, Table, onError } from "../../../modules";
import { BankService } from "../../../services";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withTransactionWraper } from "../wraper";
import { PopupWraper } from "../../../components/popup";

export const WithdrawProgress = withTransactionWraper(
  memo(() => {
    const [openPopupReject, setOpenPopupReject] = useState(null);
    const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

    const coinsState = useSelector((state) => state.coins);
    const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) => arr.map((item: any) => ({ label: item.name, value: item.coinId })));

    return (
      <>
        <div className="WithdrawProgress">
          <Table
            hasOrderColumn
            forceUpdateTable={forceUpdateTable}
            filters={[
              {
                name: "Time",
                key: "created",
                input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              },
              {
                name: "Username",
                key: "email",
                input: TableFilterInputText,
              },
              {
                name: "Address",
                key: "address",
                input: TableFilterInputText,
              },
              {
                name: "Status",
                key: "status",
                input: (s) => <TableFilterInputSelect {...s} options={BankService.getStatusOptions()} />,
                defaultValue:"JUST_CREATED"
              },
            ]}
            structure={[
              {
                name: "ADDRESS",
                key: "address",
                render: (item: any) =>
                  item?.address != null && item?.address != "" ? (
                    <CopyToClipboard
                      text={item?.address}
                      onCopy={() =>
                        CreateAlert({
                          type: "success",
                          message: "Copy Success",
                        })
                      }
                    >
                      <div className="copy">
                        <div className="copy__icon">
                          <Icon.Copy />
                        </div>
                        <div className="copy__value" title={item?.address}>
                          {item?.address.length > 12 ? `${item?.address.slice(0, 6)}...${item?.address.slice(-6)}` : item?.address}
                        </div>
                      </div>
                    </CopyToClipboard>
                  ) : (
                    "N/A"
                  ),
              },
              {
                name: "Wallets",
                key: "coinId",
                render: (item) => {
                  const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                  return ObjectUtils.getIn(coin, "label", "--");
                },
              },
              {
                name: translate("time"),
                key: "created",
                render: (item) => {
                  const date = new Date(item.created);
                  return (
                    <span>
                      {date.toLocaleTimeString(getLocaleKey())} <br />
                      {date.toLocaleDateString(getLocaleKey())}
                    </span>
                  );
                },
              },
              {
                name: "Username",
                key: "userName",
              },
              {
                name: "Value",
                key: "value",
                render: (item) =>
                  (+item.value).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4,
                  }),
              },
              {
                name: "Status",
                key: "status",
                render: (item: any) => (
                  <span className={`status status--${item?.status?.toLowerCase()}`}>{_.startCase(_.toUpper(translate(item?.status)))}</span>
                ),
              },
              {
                name: "Receiver",
                key: "receiverUserName",
              },
              {
                name: "Actions",
                render: (item, fetchData) => {
                  return (
                    <Fragment>
                      <Button
                        className="mr5"
                        type="button"
                        buttonType="success-outline"
                        label="Approve"
                        disabled={item?.status !== "JUST_CREATED"}
                        onClick={async () =>
                          AdminService.withdrawApprove(item.transactionRequestId)
                            .then(() => fetchData())
                            .catch(onError)
                        }
                      />

                      <Button
                        type="button"
                        buttonType="danger-outline"
                        label="Reject"
                        disabled={item?.status !== "JUST_CREATED"}
                        onClick={
                          async () => setOpenPopupReject(item.transactionRequestId)
                          // AdminService.withdrawReject(item.transactionRequestId)
                          //   .then(() => fetchData())
                          //   .catch(onError)
                        }
                      />
                    </Fragment>
                  );
                },
              },
            ]}
            fetchData={async (state) => {
              let params = { ...state };
              if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
              if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

              return AdminService.getListWithdrawProgress({
                page: params.pageNumber,
                numberOfItemsPerPage: params.limit,
                ...params,
              });
            }}
          />
        </div>

        {openPopupReject && (
          <PopupWraper center title="Reject Withdraw Process" onClose={() => setOpenPopupReject(null)}>
            <div className="text-center">
              <div className="mb20">Are you sure you want to cancel this withdrawal?</div>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  className="mr10"
                  type="button"
                  buttonType="danger"
                  label="Reject"
                  onClick={async () =>
                    AdminService.withdrawReject(openPopupReject)
                      .then(() => {
                        setOpenPopupReject(null);
                        setForceUpdateTable(Math.random());
                      })
                      .catch(onError)
                  }
                />
                <Button type="button" buttonType="success" label="Quit" onClick={() => setOpenPopupReject(null)} />
              </div>
            </div>
          </PopupWraper>
        )}
      </>
    );
  })
);
