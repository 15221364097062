import React, { FC, useEffect, useState } from "react";
import XLSX from "xlsx";
import {  TableFilterRangeTimeInput } from "../../components/table-filter-inputs";
import { Button, CreateAlert, Table } from "../../modules";
import { AdminService } from "../../services/admin";
import moment from "moment";
import { useSelector } from "../../store";
import Big from "big.js";

export const BridgeSystemV2Page: FC = (props: any) => {

    const coins = useSelector((state) => state.coins);
    
    const [data, setData] = useState<any>();
    const [params, setParams] = useState([] as any[]);

    let priceStructure = [
        {
            name: 'BTC Bridge',
            key: 'BTCUSDT',
        },
        {
            name: 'ETH Bridge',
            key: 'ETHUSDT',
        },
        {
            name: 'GOLD Bridge',
            key: 'GOLDUSD',
        },
        {
            name: 'OIL Bridge',
            key: 'OILUSD',
        },
    ];

    let orderStructure = [
        {
            name: "Product",
            key: "symbolName",
        },
        {
            name: "Exchange",
            key: "exchange",
        },
        {
            name: "Order Type",
            key: "option",
        },
        {
            name: "Open Price",
            key: "openPrice",
            render: (item:any) => (item?.openPrice != null) ? new Intl.NumberFormat('en-US').format(item?.openPrice) : 'N/A',
        },
        {
            name: "Open Time",
            key: "created",
            render: (item:any) => moment(item.created).format("L HH:mm:ss")
        },
        {
            name: "Close Price",
            key: "closePrice",
            render: (item:any) => (item?.closePrice != null) ? new Intl.NumberFormat('en-US').format(item?.closePrice) : 'N/A',
        },
        {
            name: "Close Time",
            key: "modified",
            render: (item:any) => moment(item.modified).format("L HH:mm:ss")
        },
        {
            name: "Order Volume",
            key: "amount",
            render: (item:any) => (item?.amount != null) ? new Intl.NumberFormat('en-US').format(item?.amount) : 'N/A',
        },
        {
            name: "Leverage",
            key: "leverage",
            render: (item:any) => (item?.leverage != null) ? new Intl.NumberFormat('en-US').format(item?.leverage) : 'N/A',
        },
        {
            name: "Profit/Loss",
            key: "profitForBot",
            render: (item:any) => (item?.leverage != null) ? <span className={`pnl pnl--${item?.profitForBot >= 0 ? 'profit' : 'loss'}`}>{(item?.profitForBot < 0) && '-'}{new Intl.NumberFormat('en-US').format(Math.abs(item?.profitForBot))}</span> : 'N/A',
        },
    ];

    const exchanges = Array.from(new Set(data?.map((item:any) => item?.exchange)));

    let handleExportExcel = () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getOrderBridgeSystemV2List({
                    ...params,
                    page: 1,
                    pageSize: 1000000,
                });

                const fileHead = orderStructure.map((v) => v.name);
                const dataExport = [
                    fileHead,
                    ...response?.result?.orders.map((item: any) =>
                        orderStructure.map((column, index) => {
                            if (!column.key) return "";
                            if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
                            if (column.key === "modified") return moment(item[column.key]).format("L HH:mm:ss");;
                            return item[column.key];
                        })
                    ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(wb, `Bridge System V2 Transaction ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
                    type: "binary",
                });

                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    })
                );
            } catch (error: any) {
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    useEffect(() => {
        AdminService.getBridgeSystemV2({}).then((res) => {
            setData(res?.result);
        });
        const reFetch = setInterval(() => {
            AdminService.getBridgeSystemV2({}).then((res) => {
                setData(res?.result);
            });
        }, 10000);
        return () => {
            clearInterval(reFetch);
        }
    }, []);

    return (
        <div className="bridge-system-v2-page">
            <div className="bridge-system-v2__price container-fluid">
                <div className="row">
                    {priceStructure?.map((item:any, idx:number) => (
                        <div key={idx} className="col">
                            <div className="bridge-system-v2__price__header">{item?.name}</div>
                            {data?.filter((x:any) => x.symbol === item.key).map((element:any, idx:number) => (
                                <div className="bridge-system-v2__price__item">
                                    <div className="bridge-system-v2__price__item__label">{element?.exchange}</div>
                                    <div className="bridge-system-v2__price__item__value">{(element?.price != null) ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Number((new Big(element?.price)).toFixed(2, Big.roundDown))) : 'N/A'}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="bridge-system-v2__order">
                <div style={{fontSize: '20px', fontWeight: '700', margin: '40px 0 12px'}}>Transactions</div>
                <Button 
                    className="mb20" 
                    label="Export to Excel" 
                    buttonType="success" 
                    onClick={handleExportExcel} 
                />
                <Table
                    hasOrderColumn
                    hasSearchButton
                    filters={[
                        {
                            name: "Date",
                            key: "created",
                            input: (e) => (
                                <TableFilterRangeTimeInput
                                    {...e}
                                    fromKey="fromDate"
                                    toKey="toDate"
                                />
                            ),
                            defaultValue: {
                                fromDate: moment().startOf("month"), 
                                toDate: moment().endOf("day")
                            },
                        },
                    ]}
                    structure={orderStructure}
                    fetchData={async (state) => {
                        setParams(state);
                        return AdminService.getOrderBridgeSystemV2List({
                            ...state,
                            page: state.pageNumber,
                            pageSize: state.limit,
                        }).then((res) => {
                            return {
                                count: res?.result?.total,
                                data: res?.result?.orders,
                            };
                        });
                    }}
                />
            </div>
        </div>
    );
};
