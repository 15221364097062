import capitalize from "lodash/capitalize";
import moment from "moment";
import React, { memo, useState } from "react";
import XLSX from "xlsx";
import { CheckboxCompnt } from "../../../components/checkbox-compnt";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getEnv } from "../../../configs";
import { getLocaleKey, translate } from "../../../languages";
import { Button, Icon, NumberUtils, ObjectUtils, Table } from "../../../modules";
import { BankService } from "../../../services";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withTransactionWraper } from "../wraper";
import { ENetWork, EUserRole } from "../../../types";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";

export const TransactionList = withTransactionWraper(
  memo(
    () => {
      const user = useSelector((state) => state.user);
      const history = useHistory();
      if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
        history.replace(Routes.dashboard.path);
        return null;
      }
      //const last1MothDate = Date.now() - 1000 * 60 * 60 * 24 * 30;

      const startOfMonth = moment().startOf("month");
      const endOfDay = moment().endOf("day");

      const coinsState = useSelector((state) => state.coins);
      const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
        arr.map((item: any) => ({
          label: item.coinId === 7 ? "Ticket" : item.name,
          value: item.coinId,
        }))
      );
      // const UsdId = 2;
      // const ButId = 5;
      // const DemoId = 4;
      // const Gp2Id = 5;

      const [currentData, setCurrentData] = useState(null as any);
      const [params, setParams] = useState<any>();
      const [isCheckAdmin, setIsCheckAdmin] = useState<boolean>(false);
      const [forceUpdateTable, setForceUpdateTable] = useState<boolean>();

      return (
        <div className="TransactionList">
          <div className="button-container">
            <Button
              disabled={!currentData}
              label="Export to Excel"
              buttonType="success"
              className="mb15"
              onClick={async () => {
                const response = await AdminService.getListTransactions({
                  ...params,
                  page: 1,
                  numberOfTransactionsPerPage: 1000000,
                });
                const data = [
                  ["Coin", "Time", "Username", "Amount", `${isCheckAdmin ? "Description" : "Type"}`, "TxHash"],
                  ...response.data.map((item: any) => {
                    const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                    const { balanceAfter, balanceBefore } = item;
                    let isIncrease = false;
                    if (balanceAfter > balanceBefore) isIncrease = true;
                    const hash = ObjectUtils.getIn(item, "transactionHash");
                    let row: any[] = [];
                    row.push(ObjectUtils.getIn(coin, "label", "--"));
                    row.push(moment(item?.created).format("L LTS"));
                    row.push(item?.userName);
                    row.push((isIncrease ? "+" : "-") + NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY")));
                    row.push(
                      isCheckAdmin ? item?.description : ObjectUtils.getIn(capitalize(item.transactionType.name.replace(/_/g, " ")), "", "--")
                    );
                    row.push(hash ? (item.network == ENetWork.BEP20 ? `${getEnv("BSC_SCAN")}${hash}` : `${getEnv("TRON_SCAN")}${hash}`) : null);

                    return row;
                  }),
                ];

                const ws = XLSX.utils.aoa_to_sheet(data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(
                  wb,
                  `Transaction-List-${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
                  { type: "binary" }
                );
              }}
            />
            {/* <div className="left-container">
                            <div className="admin-checkbox">
                                <div className="label">ADMIN ONLY</div>
                                <CheckboxCompnt 
                                    // value={} 
                                    checked={isCheckAdmin}
                                    onChange={(value: any, checked: any) => {
                                        setIsCheckAdmin(checked);
                                        setForceUpdateTable(state => !state);
                                    }}
                                />
                            </div>
                        </div> */}

            <div className="infor-container">
              <div className="total-amount">
                {currentData?.coinActive !== 7 && (
                  <div className="total-amount__icon">
                    <Icon.TotalVolumeIcon />
                  </div>
                )}
                <div className="total-amount__content">
                  {NumberUtils.toFormatNumber(currentData?.total ?? 0, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                  <span className="sub-text">&nbsp;&nbsp;TOTAL AMOUNT</span>
                </div>
              </div>
            </div>
          </div>

          <Table
            hasOrderColumn
            hasSearchButton
            itemPerPages={10}
            filters={[
              {
                name: "Wallets",
                key: "coinId",
                input: (e) => (
                  <TableFilterInputSelect
                    {...e}
                    isClearable={false}
                    // options={coinsOptions.filter((item: any) => item.value !== 6)}
                    options={coinsOptions}
                  />
                ),
                defaultValue: 3,
              },
              {
                name: "Time",
                key: "created",
                input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
                defaultValue: {
                  fromDate: startOfMonth, //new Date(last1MothDate),
                  toDate: endOfDay, //new Date(),
                },
              },
              {
                name: "Username",
                key: "email",
                input: TableFilterInputText,
              },
              // {
              //   name: "Email",
              //   key: "email",
              //   input: TableFilterInputText,
              // },
              // {
              //     name: "Amount",
              //     key: "amount",
              //     input: TableFilterInputNumber,
              // },
              {
                name: "Type",
                key: "transactionTypeId",
                input: (s) => <TableFilterInputSelect {...s} isSearchable options={BankService.getTransactionTypeOptions()} />,
              },
              // {
              //   name: "Only Admin",
              //   key: "",
              //   input: (s) => (
              //     <div className="admin-checkbox">
              //       <CheckboxCompnt
              //         // value={}
              //         {...s}
              //         checked={isCheckAdmin}
              //         onChange={(value: any, checked: any) => {
              //           setIsCheckAdmin(checked);
              //           setForceUpdateTable((state) => !state);
              //         }}
              //       />
              //       <div className="label" onClick={() => setIsCheckAdmin((state) => !state)}>
              //         ADMINISTRATOR
              //       </div>
              //     </div>
              //   ),
              // },
            ]}
            structure={[
              {
                name: "Wallets",
                key: "coinId",
                className: "coin",
                render: (item) => {
                  const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                  let coinAvatar;
                  switch (coin?.value) {
                    case 3: {
                      coinAvatar = "usdt.png";
                      break;
                    }
                    case 7: {
                      coinAvatar = "ticket.png";
                      break;
                    }
                    default: {
                      coinAvatar = "usd.png";
                      break;
                    }
                  }
                  return (
                    <>
                      <img className="coin-avatar" src={`/assets/images/coins/${coinAvatar}`} alt="" />
                      <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
                    </>
                  );
                },
              },
              {
                name: "Time",
                key: "created",
                render: (item) => {
                  const date = new Date(item.created);
                  return (
                    <span>
                      {date.toLocaleDateString(getLocaleKey())} {date.toLocaleTimeString(getLocaleKey())}
                    </span>
                  );
                },
              },
              {
                name: "Username",
                key: "userName",
                className: "email",
              },
              // {
              //   name: "Email",
              //   key: "email",
              //   className: "email",
              // },
              {
                name: "Amount",
                key: "value",
                render: (item) => {
                  const { balanceAfter, balanceBefore } = item;
                  let isIncrease = false;
                  if (balanceAfter > balanceBefore) isIncrease = true;
                  return (
                    <span className={isIncrease ? "positive-value" : "negative-value"}>
                      {isIncrease ? "+" : "-"}
                      {item?.coinId === 7 ? +item?.value : NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                    </span>
                  );
                },
              },
              {
                name: "Balance After",
                key: "balanceAfter",
                render: (item) =>
                  (+item.balanceAfter).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4,
                  }),
              },
              {
                name: isCheckAdmin ? "Description" : "Type",
                key: "transactionTypeId",
                render: (item) => (isCheckAdmin ? item?.description : translate(item.transactionType.name)),
                // ObjectUtils.getIn(
                //   capitalize(item.transactionType.name.replace(/_/g, " ")),
                //   "",
                //   "--"
                // ),
                //render: (item) => ObjectUtils.getIn(item, 'transactionType.name', '--'),
              },
              // {
              //     name: translate("description"),
              //     key: "description",
              // },
              {
                name: "TxHash",
                key: "description",
                render: (item) => {
                  const hash = ObjectUtils.getIn(item, "transactionHash");
                  if (hash) {
                    switch (item.network) {
                      case ENetWork.BEP20: {
                        return (
                          <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                            CLICK ME ({ENetWork.BEP20})
                          </a>
                        );
                      }

                      case ENetWork.TRC20: {
                        return (
                          <a href={`${getEnv("TRON_SCAN")}${hash}`} target="__blank">
                            CLICK ME ({ENetWork.TRC20})
                          </a>
                        );
                      }
                      default: {
                        return (
                          <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                            CLICK ME ({ENetWork.BEP20})
                          </a>
                        );
                      }
                    }
                  }
                  return "--";
                },
              },
            ]}
            fetchData={async (state) => {
              let params = { ...state };
              setParams(params);
              if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
              if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
              if (isCheckAdmin === true) {
                params["email"] = getEnv("ADMIN_EMAIL");
              }

              return AdminService.getListTransactions({
                page: params.pageNumber,
                numberOfTransactionsPerPage: params.limit,
                ...params,
              }).then((res) => {
                setCurrentData(res);
                return res;
              });
            }}
            forceUpdateTable={forceUpdateTable}
          />
        </div>
      );
    },
    () => true
  )
);
